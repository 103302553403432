import {asyncRoutes, constantRoutes } from '@/router'

const generateOptions = (routes) => {
    const options = []
    const map = {}
    routes && routes.map && routes.map(({ meta: { group, module, title: label, sort }, name: value, hidden }) => {
        if (!Object.hasOwnProperty.call(map, module)) {
            map[module] = options.length
            options.push({ title: group, module, sort, children: []})
        }
        options[map[module]].children.push({ value, label, hidden: hidden ?? false })
    })
    return options.sort((a, b)=> a.sort - b.sort)
}

export function filterAsyncRoutes(routes, routeNames) {
    const res = []
    const routeMap = {}
    routes.map(route => {
        routeMap[route.name] = route
    })

    Object.keys(routeNames).map(k=>{
        routeNames[k].map(routeName=> {
            routeMap[routeName] && res.push(routeMap[routeName])
        })
    })

    return res
}

const state = {
    routes: [],
    addRoutes: [],
    options: [],
    driver: {
        mac: undefined,
        host: undefined,
        name: undefined
    }
}

const mutations = {
    SET_ROUTES: (state, routes) => {
        state.addRoutes = routes
        state.routes = constantRoutes.concat(routes)
        state.options = generateOptions(routes)
    },
    SET_DRIVER: (state, { mac, host, name }) => {
        state.mac = mac
        state.host = host
        state.name = name
    }
}

const actions = {
    generateRoutes({ commit }, {roles, routes}) {
        return new Promise(resolve => {
            let accessedRoutes
            if (roles.includes('super')) {
                accessedRoutes = asyncRoutes || []
            } else {
                accessedRoutes = filterAsyncRoutes(asyncRoutes, routes)
            }
            commit('SET_ROUTES', accessedRoutes)
            resolve(accessedRoutes)
        })
    },
    setDriverInfo({ commit }, { mac, host, name }) {
        commit('SET_DRIVER', { mac, host, name })
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
