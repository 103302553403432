
export default {
    title: '权限&角色',
    sort: 1,
    parent: 'root',
    routes: [
        {
            path: '/admin',
            component: () => import('@/views/permission/AdminList.vue'),
            meta: { title: '账号', icon: 'fa-user-tie' }
        },
        {
            path: '/group',
            component: () => import('@/views/permission/GroupList.vue'),
            meta: { title: '分组', icon: 'fa-bookmark' }
        },
        {
            path: '/role',
            component: () => import('@/views/permission/RoleList.vue'),
            meta: { title: '角色', icon: 'fa-tags' }
        },
        {
            path: '/admin-list',
            component: () => import('@/views/admin/List.vue'),
            meta: { title: '管理员', icon: 'fa-user-tie' }
        },
        {
            path: '/admin-group-list',
            component: () => import('@/views/admin/group/List.vue'),
            meta: { title: '管理员分组', icon: 'fa-user-tie' }
        },
        // {
        //     path: '/group-list',
        //     component: () => import('@/views/admin/grpup.vue'),
        //     meta: { title: '管理员', icon: 'fa-user-tie' }
        // },
        // {
        //     path: '/role-list',
        //     component: () => import('@/views/admin/role.vue'),
        //     meta: { title: '管理员', icon: 'fa-user-tie' }
        // },
    ]
}
