import request, {PLATFORM_API} from '@/utils/request'
// const { host, code: auth_code } = getInfo()

export function adminLogin(data) {
    return request({
        baseURL: PLATFORM_API,
        url: '/manage/v1/login',
        method: 'post',
        data
    });
}

export function adminInfo() {
    return request({
        baseURL: PLATFORM_API,
        url: '/manage/v1/admin/info',
        method: 'get',
    })
}

export function getAdminList(query) {
    return request({
        url: '/manage/v1/admin/contents',
        method: 'get',
        params: {...query},
        baseURL: PLATFORM_API
    });
}

export function addAdmin(data) {
    return request({
        url: '/manage/v1/admin/add',
        method: 'post',
        baseURL: PLATFORM_API,
        data
    });
}

export function modifyAdmin(id, data) {
    data.admin_id = id
    return request({
        url: '/manage/v1/admin/modify',
        method: 'post',
        baseURL: PLATFORM_API,
        data
    });
}


export function delAdmin(data) {
    return request({
        baseUrl: PLATFORM_API,
        url: '/manage/v1/admin',
        method: 'delete',
        data
    })
}
