import { getToken, removeToken, hasToken, setToken } from '@/utils/auth'
import { resetRouter } from '@/router'
import { adminLogin, adminInfo } from '@/api/admin/admin'
import { closeChat } from '@/chat'
import { setInfo as setPlatformInfo, removeInfo as removePlatformInfo } from '@/utils/platform'
import { PLATFORM_API } from "@/utils/request";

const state = {
    token: getToken(),
    name: '',
    id: undefined,
    status: undefined,
    introduction: '',
    roles: [],
    routes: []
}
const mutations = {
    SET_TOKEN: (state, token) => {
        state.token = token
    },
    SET_ROLES: (state, roles) => {
        state.roles = roles
    },
    SET_ROUTES: (state, routes) => {
        state.routes = routes
    },
    SET_PHONE: (state, phone) => {
        state.phone = phone
    },
    SET_ID: (state, id) => {
        state.id = id
    },
    SET_NAME: (state, name) => {
        state.name = name
    },
    SET_STATUS: (state, status) => {
        state.status = status
    }
}

const actions = {
    // 包含 wss 的登录操作
    login({ commit/**, dispatch **/}, data) {
        // 新版
        return new Promise((resolve, reject) => {
            let sendForm = {
                'username': data.account,
                'password': data.password
            }
            adminLogin(sendForm).then( response => {
                //if( response.code === 20000) {
                commit('SET_TOKEN', response.data.token);
                setToken(response.data.token)
                setPlatformInfo({
                    code: '',
                    host: PLATFORM_API
                })
                //}
                resolve()
            }).catch(err => {
                reject(err)
            })
        })
    },
    logout({ commit }) {
        return  new Promise((resolve, reject) => {
            if (hasToken()) {
                removeToken()
                removePlatformInfo()
                resetRouter()
                commit('SET_ROLES', [])
                commit('SET_ROUTES', [])
                closeChat()
                resolve()
            } else {
                reject('已退出')
            }
        })
    },
    // remove token
    clear({ commit }) {
        return new Promise(resolve => {
            commit('SET_TOKEN', '')
            removeToken()
            removePlatformInfo()
            resolve()
        })
    },
    info({ commit }) {
        return new Promise((resolve, reject) => {
            adminInfo().then( response => {
                const { data } = response
                if (!data) {
                    reject('Verification failed, please Login again.')
                }
                const { base: { username }, auth: { status, phone, id }, routes, roles } = data
                commit('SET_PHONE', phone)
                commit('SET_ID', id)
                commit('SET_NAME', username)
                commit('SET_STATUS', status)
                commit('SET_ROLES', roles)
                commit('SET_ROUTES', routes)
                resolve({...data, routes, roles })
            }).catch(err => {
                reject(err)
            })
        })
        // return new Promise((resolve, reject) => {
        //     info().then(response => {
        //         const { data } = response
        //         if (!data) {
        //             reject('Verification failed, please Login again.')
        //         }
        //         const { base: { username }, auth: { status, phone, id }, routes, roles } = data
        //         commit('SET_PHONE', phone)
        //         commit('SET_ID', id)
        //         commit('SET_NAME', username)
        //         commit('SET_STATUS', status)
        //         commit('SET_ROLES', roles)
        //         commit('SET_ROUTES', routes)
        //         resolve({...data, routes, roles })
        //     }).catch(err => {
        //         reject(err)
        //     })
        //
        // })
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
